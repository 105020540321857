<template>
  <div class="wx-container">
    <img class="logoIcon" src="../../assets/images/wxlogin/icon_1.png" >
    <img class="warnImg" :src="warnImg" >
    <p class="warnText">{{warnText}}</p>
  </div>
</template>

<script>
	import Api from "@/server";
	export default {
		data() {
			return {
        warnText: '',
        warnImg: '',
        sucImg: require('../../assets/images/wxlogin/icon_4.png'),
        errImg: require('../../assets/images/wxlogin/icon_5.png'),
      }
    },
    mounted() {
      let url = window.location.href,
          hasBind = Api.getUrlParams(url,'hasBind');
      if(hasBind == 'true'){
        this.warnImg = this.sucImg;
        this.warnText = '绑定成功';
      }else{
        this.warnImg = this.errImg;
        this.warnText = '绑定失败';
      }
    },
  }
</script>

<style lang="less" scoped>
  .wx-container{
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/images/wxlogin/bg.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6vh;
    
    .logoIcon{
      width: 90vw;
      height: auto;
      margin-bottom: 5vh;
    }
    
    // .systemTitle{
    //   font-size: 6.76vw;
    //   color: #ffffff;
    //   font-weight: bold;
    //   margin: 2vh 0;
    // }
    
    .sonSystemTitle{
      height: 2vh;
      margin-bottom: 6vh;
      display: flex;
      align-items: center;
      
      img{
        width: 21vw;
        height: .2vh;
      }
      
      span{
        font-size: 4.8vw;
        color: #ffffff;
        opacity: 0.8;
        margin: 0 3vw;
      }
    }
    
    .warnImg{
      width: 20vw;
      height: 20vw;
    }
    
    .warnText{
      width: 30vw;
      height: 4vh;
      background-color: rgba(0,0,0,.22);
      border-radius: 4.5vw;
      margin-top: 2vh;
      font-size: 4.3vw;
      color: #fff;
      text-align: center;
      line-height: 4vh;
    }
  }
</style>
